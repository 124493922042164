/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        // JavaScript to be fired on all pages

        function toggleHeader() {
          const header = document.getElementById("myHeader");
          if (window.scrollY > 0) {
            header.classList.add("scrolled");
          } else {
            header.classList.remove("scrolled");
          }
        }

        window.addEventListener("scroll", function () {
          toggleHeader();
        });
        $(document).ready(function () {
          toggleHeader();
        });

        const header = document.querySelector("#myHeader");
        document
          .querySelector(".navbar-toggler")
          .addEventListener("click", function () {
            header.classList.toggle("show");
          });

        const swiper = new Swiper(".swiper", {
          watchOverflow: true,
          pagination: {
            clickable: true,
            el: ".swiper-pagination",
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          breakpoints: {
            320: {
              slidesPerView: 1,
              spaceBetween: 24,
            },
            769: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            992: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          },
        });

        const planSwiper = new Swiper(".swiper-plans", {
          watchOverflow: true,
          pagination: {
            clickable: true,
            el: ".swiper-pagination",
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          breakpoints: {
            320: {
              slidesPerView: 1,
              spaceBetween: 24,
            },
            769: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            992: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          },
        });

        const benefitsSwiper = new Swiper(".swiper-benefits", {
          watchOverflow: true,
          slidesPerView: 1,
          autoHeight: true,
          pagination: {
            clickable: true,
            el: ".swiper-pagination",
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired

        /* START SELECT 2! */
        function formatState(state) {
          if (!state.id || !$(state.element).attr("data-iconurl")) {
            return state.text;
          }
          var iconUrl = $(state.element).attr("data-iconurl");
          var baseUrl = iconUrl;
          var $state = $(
            '<span><img src="' +
            baseUrl +
            '" class="img-flag" width="22px" style="margin-right: 20px;"/> ' +
            state.text +
            "</span>"
          );
          return $state;
        }

        $(".js-select2")
          .select2({
            minimumResultsForSearch: Infinity,
            templateResult: formatState,
          })
          .on("select2:open", function (e) {
            setTimeout(function () {
              $(".select2-dropdown").addClass("opened");
            }, 200);
          })
          .on("select2:closing", function (e) {
            // if removed, for some examples, the Select2 will not highlight the selected element
            $(".select2-dropdown").removeClass("opened");
          });
        /* END SELECT 2! */

        /* GRAVITY FORMS */
        if ($(".ginput_container_fileupload").length > 0) {
          $('<i class="attachment"></i>').insertAfter(
            ".ginput_container_fileupload input[type=file]"
          );
          $(".ginput_container_fileupload i.attachment").on(
            "click",
            function () {
              $(this).prev().trigger("click");
            }
          );
        }
        /* END GRAVITY FORMS */
      },
    },
    // Home page
    home: {
      init: function () {},
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // About us page, note the change from about-us to about_us.
    about_us: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
    },
    // Manual
    page_template_tpl_manual: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        $(window).scroll(function () {
          if ($(window).width() > 1024) {
            if ($(window).scrollTop() >= $(".areas-wrapper").position().top) {
              $(".menu-wrapper").addClass("is-sticky");
            } else {
              $(".menu-wrapper").removeClass("is-sticky");
            }
          }
        });
      },
    },
    woocommerce_cart: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        $('body').on('removed_from_cart', function (event, fragments, cart_hash, $removed_item) {
          console.log("entrei!!!");
          $.ajax({
            type: 'POST',
            url: ajaxurl,
            data: {
              action: 'update_cart_fragments',
              cart_hash: cart_hash
            },
            success: function (response) {
              if (response && response.fragments) {
                $.each(response.fragments, function (key, value) {
                  $(key).replaceWith(value);
                });
                if (response.cart_hash)
                  $('input[name="cart_hash"]').val(response.cart_hash);
              }
            }
          });
        });
      },
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
